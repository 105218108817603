
import React from 'react';

import './Ooo.css'
import Adam from '../../img/adam-ooo.png'

function Ooo () {
  return (
    <div className="ooo">
      <div className="ooo-content">
        <div>Adam is OOO from December 22, 2022 -  January 4, 2023</div>
        <img src={Adam} alt="OOO" />
      </div>

    </div>
  )
}

export default Ooo
